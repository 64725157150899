// Store
export const FETCH_STORE_INFO_START = 'FETCH_STORE_INFO_START'
export const FETCH_STORE_INFO_END = 'FETCH_STORE_INFO_END'
export const FETCH_STORE_INFO_ERROR = 'FETCH_STORE_INFO_ERROR'

// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'
export const CLEAR_CART = 'CLEAR_CART'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const REFRESH_CART_PRODUCTS = 'FETCH_CART_PRODUCTS'

// Login
export const LOGIN = 'LOGIN'

