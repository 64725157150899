
export interface ICheckoutCreateInput {
  email?: string;
  presentmentCurrencyCode?: string;
  lineItems: Array<ILineItem>;
  customAttributes: Array<ICustomAttribute>;
  shippingAddress?: IMailingAddressInput;
}

export interface IMailingAddressInput {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  company?: string;
  zip: string;
  province: string;
  country: string;
  phone?: string;
}

export interface ICustomAttribute {
  key: string;
  value: string;
}

export interface ILineItem {
  variantId: string;
  quantity: number;
  customAttributes: Array<ICustomAttribute>;
}

export function makeCheckoutCreateMutation(
  checkoutCreateInput: ICheckoutCreateInput
): { query: string, variables: { input: ICheckoutCreateInput } }  
{
  /*storefrontClient.enum(process.env.REACT_APP_CURRENCY || "USD")*/
  checkoutCreateInput.presentmentCurrencyCode = "USD";

  const query = `
    mutation checkoutCreate($input: CheckoutCreateInput!) {
      checkoutCreate(input: $input) {
        checkout {
          id
          webUrl
        }
        checkoutUserErrors {
          code
          field
          message
        }
        queueToken
      }
    }
  `

  return {
    query: query,
    variables: {
      input: checkoutCreateInput
    }
  }
}