import { useState } from 'react';
import { moneyFormat } from "@merchstores/frontend/components/Money";

export const ProductTile = (props: any): JSX.Element => {
  const { product, onClick } = props;
  const [image, setImage] = useState(product.productLogoMockupUrl);
  const productAvailable = product.variants?.some((variant: any) => variant.availableForSale);

  return (
    <div 
      key={product.id} 
      className={`
        product-tile 
        bg-white 
        px-4 
        xl:px-12 
        py-3 
        lg:py-8 
        flex 
        items-center 
        justify-between 
        text-center 
        flex-col 
        overflow-hidden
      `}
    >
      <img 
        onClick={onClick}
        src={image}
        alt={"Product Logo"}
        width="85%"
        className="cursor-pointer"
        onMouseOver={product.images?.length > 1 ? () => setImage(product.images[1].src) : undefined}
        onMouseOut={() => setImage(product.productLogoMockupUrl)}
      />
      <a 
        onClick={onClick} 
        className="store-link font-bold text-sm xl:text-lg py-3 cursor-pointer text-merch-dark-gray hover:underline"
      >
        {product.title}
      </a>
      <div className="flex">
          {productAvailable ?
          <>
            <p className="font-bold">
              {
                product.variants[0] && `${ moneyFormat(product.variants[0].price.amount) }`
              }
            </p>
            <p className="pl-2 line-through text-merch-text-disabled">
              {
                product.variants[0] && product.variants[0].compareAtPrice && 
                `${ moneyFormat(product.variants[0].compareAtPrice.amount) }`
              }
            </p>
          </>
          : <div className="flex justify-center items-center align-center bg-merch-text-disabled">
              <p className="text-white font-bold text-9 lg:text-12 py-1.5 px-2.5">OUT OF STOCK</p>
            </div>
        }
      </div>    
    </div>
  )
}