import "./styles.scss";
import { Helmet } from "react-helmet";

export const Page404 = () => { // Define the actual props needed in the index file

    return (
        <>
        <Helmet>
            <title>404</title>
        </Helmet>
        <div>404 - Page not found</div>
        </>
    )
}