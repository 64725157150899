import { useState } from "react";
import { ITooltipProps } from ".";
import "./styles.scss";

export const Tooltip = (props: ITooltipProps) => {

    let timeout: NodeJS.Timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    }

    const hideTip = () => {
        clearInterval(timeout);
        setTimeout(() => {
            setActive(false);
        }, 800);
    }

    return (
        <div
            className="Tooltip-Wrapper"
            // When to show the tooltip
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {/* Wrapping */}
            {props.children}
            {active && (
                <div className={`Tooltip-Tip ${props.place || "top"} ${props.tipClassName || ''}`}>
                    <div className="arrow"></div>
                    {/* Content */}
                    {props.content}
                </div>
            )}
        </div>
    )
}