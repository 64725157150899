import { Promise as bluebird } from 'bluebird';
import { ICartItem, ICartProduct, IProductVariant } from '@merchstores/frontend/reduxTypes';
import { getProductDetails } from '@merchstores/frontend/api/products/MerchStoreProductDetails';

export class MissingProductsError extends Error
{
  missingProductHandles: Array<string>;
  availableProducts: Array<ICartProduct>;

  constructor(
    msg: string, 
    missingProductHandles: Array<string>, 
    availableProducts: Array<ICartProduct>
  ) 
  {
    super(msg);
    this.missingProductHandles = missingProductHandles;
    this.availableProducts = availableProducts;
  }

  getMissingHandles(): Array<string>
  {
    return this.missingProductHandles;
  }

  getAvailableProducts(): Array<ICartProduct>
  {
    return this.availableProducts;
  }
}


export function mapProductsByHandle(products: Array<ICartProduct>) : Map<string, ICartProduct>
{
  const productsByHandle = new Map();

  products.forEach(product => {
    productsByHandle.set(product.handle, product);
  });

  return productsByHandle;
}

export function mapProductVariantsById(product: ICartProduct) : Map<string, IProductVariant>  
{
  const productVariantsById = new Map();

  const parentPrice = product.price;
  const parentImages = product.images;

  product.variants.forEach((productVariant) => {

    productVariant.price = productVariant.price || parentPrice;
    productVariant.images = productVariant.images || parentImages;

    productVariantsById.set(productVariant.id, productVariant);
  });

  return productVariantsById;
}

export async function fetchProductsForItems(cartItems: Array<ICartItem>) : Promise<Array<ICartProduct>>
{
  const productHandles = cartItems.map(item => item.handle);
  const missingProductHandles : Array<string> = [];

  const uniqueProductHandles = Array.from(
    new Set(productHandles).values()
  ).filter(Boolean) as string[];


  const validProducts: ICartProduct[] = [];

  await bluebird.map(uniqueProductHandles, async(productHandle: string) => {
    
    const productDetails = await getProductDetails(productHandle).catch(
      (productDetailsError: unknown) => {
        console.error("fetchProductsForItems - getProductDetails:", productDetailsError);
        return;
      }
    );

    if (!productDetails) {
      missingProductHandles.push(productHandle as string);
      return;
    }

    validProducts.push({
      handle: productDetails.handle,
      title: productDetails.title,
      /* price is string due to decimal handling, avoid float, parse at use time with bignumber */
      price: productDetails.price,
      images: productDetails.images,
      variants: productDetails.variants
    });

  }, { concurrency: 3 });


  if (missingProductHandles.length)
  {
    throw new MissingProductsError(
      `Missing products ${JSON.stringify(missingProductHandles)}`,
      missingProductHandles,
      validProducts
    );
  }

  return validProducts;
}