import { Dispatch } from 'redux';
import * as types from '../constants/ActionTypes';
import { ICartItem, ICartProduct } from '../reduxTypes';

/* * * Cart * * */
export const addToCart = (item: ICartItem) => (dispatch: Dispatch): Promise<boolean> => {
  return new Promise((resolve) => {
    dispatch({
      type: types.ADD_TO_CART,
      item
    });
    resolve(true);
  });
}

export const updateInCart = (item: ICartItem) => (dispatch: Dispatch): Promise<boolean> => {
  return new Promise((resolve) => {
    dispatch({
      type: types.UPDATE_CART_ITEM,
      item
    });
    resolve(true);
  });
}

export const removeFromCart = (item: ICartItem) => (dispatch: Dispatch): Promise<boolean> => {
  return new Promise((resolve) => {
    dispatch({
      type: types.REMOVE_FROM_CART,
      item
    });
    resolve(true);
  });
}

export const clearCart = () => (dispatch: Dispatch): Promise<boolean> => {
  return new Promise((resolve) => {
    dispatch({
      type: types.CLEAR_CART
    });
    resolve(true);
  });
}

export const refreshCartProducts = (products: Array<ICartProduct>) => (dispatch: Dispatch): Promise<boolean> => {
  return new Promise((resolve) => {
    dispatch({
      type: types.REFRESH_CART_PRODUCTS,
      products
    });
    resolve(true);
  });
}
