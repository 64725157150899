import LoadingGif from "@merchstores/shared/assets/common/Merchology-motion-graphic-cropped.gif"

export interface ILoadingProps {
  isLoading: boolean;
  width?: string;
}

export const Loading: React.FC<ILoadingProps> = (props: ILoadingProps) => {
  const isLoading = props.isLoading;
  const width = props.width || "100px";

  if (isLoading)
  {
    return (<div 
      className="h-full flex items-center justify-center"
      data-loading={isLoading}>
      <img 
        src={LoadingGif} 
        alt="loading icon" 
        width={width}
      />
    </div>)
  }

  return (
    <div data-loading={isLoading}></div>
  )
}

