import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import dompurify from 'dompurify';

import { IProductPageProps, IProductsParamsProps } from './index';
import "./styles.scss";
import { isApparel as useIsApparel } from '../../api/products/MerchStoreProductType';

export const ProductPageDetails = (props: IProductPageProps) => { // Define the actual props needed in the index file
    const history = useHistory();
    const DOMPurify = dompurify(window);

    const { productHandle } = useParams<IProductsParamsProps>();

    const [product, setProduct] = useState(props.product);
    const [description, setDescription] = useState("")

    const [productSpecs, setProductSpecs] = useState([])

    const isApparel = useIsApparel(product?.productType);

    const headingStyles = "pdp-description-text font-bold text-2xl text-merch-dark-gray mb-3"
    const listItemStyles = "pdp-description-text  text-12 mb-2 ml-4"

    useEffect(() => {

        if (props.product) {

            setProduct(props.product);

            const product = props.product;

            if (product.descriptionHtml) {
                const specs = product.descriptionHtml.match(/<li>.*<\/li>/gi);
                setProductSpecs(specs)

                const descriptionWithoutDetails = product.descriptionHtml.replace(/<details[^]*<\/details>/gi, "")
                // if there is a <p> tag in the descriptionHtml, remove it. If not, just use descriptionWithoutDetails
                const descriptionWithoutPTag = descriptionWithoutDetails.search('<p>') ?
                    descriptionWithoutDetails.replace(/<p.*<\/p>/gi, "")
                    : descriptionWithoutDetails
                // if there is a <span> tag in the descriptionHtml, remove it. If not, just use descriptionWithoutPTag
                const desctiptionWithoutSpanTag = descriptionWithoutPTag.search('<span>') ?
                    descriptionWithoutPTag.replace(/<span.*<\/span>/gi, "")
                    : descriptionWithoutPTag
                
                setDescription(desctiptionWithoutSpanTag)
            }


        } else {
            console.log(`No product found with handle ${productHandle}`);
            history.push("/404");
        }

    }, [props.product]);

    function createMarkup(text: string) { return {__html: DOMPurify.sanitize(text)} }

    if (!product) {
        return <></>
    }
    
    return (
        <div className="productInfoContainer flex-col items-center justify-center w-full px-15 md:px-50 xl:px-200" >
            {description ?
                <div className="productDetails flex-col items-center justify-center mt-50">
                    <h2 className={headingStyles}>Product Details</h2>
                    <div 
                        className="pdp-description-text text-base"
                        dangerouslySetInnerHTML={createMarkup(description)} >
                    </div>
                </div>
            : null }
            {productSpecs?.length ?
                <div className="productSpecs flex-col items-center justify-center mt-30">
                    <h2 className={headingStyles}>Specs</h2>
                    <ul className="pdp-description-text text-left list-disc list-outside">
                        {productSpecs && productSpecs.map((spec: string) =>
                            <div 
                                className={listItemStyles} key="spec"
                                dangerouslySetInnerHTML={createMarkup(spec)} >    
                            </div>
                        )}
                    </ul>
                </div>
            : null }
            {isApparel ?
                <div className="pdp-description-text productSizing flex-col items-center justify-center mt-30">
                    <h2 className={headingStyles}>Sizing</h2>
                    <p className="text-base font-bold">Standard</p>
                    <p className="text-base">Comfortable and easy to wear</p>
                    <a href={`https://www.merchology.com/pages/products-sizing-tabs#${product.vendor?.toLowerCase()}`}
                        rel="noreferrer"
                        target="_blank"
                        className="pdp-description-text store-link block text-base text-merch-dark-gray font-medium underline mt-2"
                    >
                        Size Charts
                    </a>
                </div>
            : null }
            <div className="pdp-description-text productDelivery flex-col items-center justify-center mt-30 pb-70">
                <h2 className={headingStyles}>Delivery</h2>
                <p> 
                    <span className="text-base">All MerchStore orders will be decorated and shipped from our facility
                    once your group order has fully processed.
                    </span>
                    <br/>
                    <br/>
                    <span className="text-base">If your MerchStore admin has designated an upcoming order cut-off date, our process lead times will begin at that 
                    point. If you need more information, please contact us or your MerchStore admin.</span>
                </p>
            </div>
        </div>
    );
}