import { ICart, IStoreState } from '@merchstores/frontend/reduxTypes';
import { shallowEqual, useSelector } from 'react-redux';
import { CartProductListItem } from './CartProductListItem';
import { buildProductStyles } from './CartProductStyles';

import { countStyles } from './CartCount';

export interface ICartProductListProps {
  display?: boolean
}

export const CartProductList : React.FC<ICartProductListProps> = (props: ICartProductListProps) => {
  
  const cart: ICart = useSelector((state: IStoreState) => state.cart, shallowEqual)

  const products = cart.products;
  const items = cart.items;

  const productStyles = buildProductStyles(items, products);
  const stylesCount = countStyles(productStyles);

  const itemsCount = cart.itemCount || 0;
  
  if (props.display === false)
  {
    return null;
  }

  return (
    <div className="cart-product-list">
      <div className="cart-product-list-counters mb-8">
        <span data-counter-name="product-count">
        { stylesCount } 
        </span> 
        { stylesCount > 1 ? " Styles (" : " Style (" }
        
        <span data-counter-name="item-count">
        { itemsCount }
        </span> 
        { itemsCount > 1 ? " Items" : " Item"}) in cart
      </div>

      {
      productStyles.map(productStyle => {
        return (
          <CartProductListItem 
            key={productStyle.styleKey} 
            product={productStyle.product} 
            items={ productStyle.items }
          />
        )
      })
    }
      
    </div>
  )
}