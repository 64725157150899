import "./styles.scss";
import { IFormInputNumberProps } from './index';
import { MdErrorOutline, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useState } from "react";
import { useEffect } from "react";

export const FormInputNumber = (props: IFormInputNumberProps) => { // Define the actual props needed in the index file

  const [emptyInput, setEmptyInput] = useState(false);
  const [currentValue, setCurrentValue] = useState(props.defaultValue);
  const [downArrowDisabled, setDownArrowDisabled] = useState(false);

  useEffect(() => {
    if (props.defaultValue !== currentValue) {
      setCurrentValue(props.defaultValue);
    }
    if (props.defaultValue) {
      setEmptyInput(false);
    } else {
      setEmptyInput(true);
    }
  }, [props.defaultValue]);

  useEffect(() => {

    if (props.positiveOnly) {
      if (currentValue <= 1) {
        setDownArrowDisabled(true);
      } else {
        setDownArrowDisabled(false);
      }
    }
  }, [currentValue]);

  const onInputChange = (ev: any) => {
    let newValue = Number(ev.target.value);
    if (props.positiveOnly && newValue <= 0) {
      setCurrentValue(1);
    } else if (props.maxAllowed && newValue >= props.maxAllowed) {
      console.log('in max allowed');
      setCurrentValue(props.maxAllowed)
    } else if (props.integerOnly && !Number.isInteger(newValue)) {
      newValue = Math.round(currentValue);
      setCurrentValue(newValue);
    } else {
      setCurrentValue(newValue);
    }

    if (props.onChange) {
      props.onChange(newValue, props.id);
    }
  }

  const addOne = () => {
    let newValue = currentValue + 1;
    if (props.onChange) {
      props.onChange(newValue, props.id);
    } else {
      if (props.positiveOnly && newValue <= 0) {
        setCurrentValue(1);
      } else if (props.maxAllowed && newValue >= props.maxAllowed) {
        setCurrentValue(props.maxAllowed)
      } else if (props.integerOnly && !Number.isInteger(newValue)) {
        newValue = Math.round(currentValue);
        setCurrentValue(newValue);
      } else {
        setCurrentValue(newValue);
      }
    }
  }

  const substractOne = () => {
    if (!downArrowDisabled) {
      let newValue = currentValue - 1;
      if (props.positiveOnly && newValue <= 0) {
        setCurrentValue(1);
      } else if (props.integerOnly && !Number.isInteger(newValue)) {
        newValue = Math.round(currentValue);
        setCurrentValue(newValue);
      } else {
        setCurrentValue(newValue);
      }

      if (props.onChange) {
        props.onChange(newValue, props.id);
      }
    }
  }

  return <>
    <div className={`form-input-container flex flex-col w-full form-input-number`}>
      {
        props.label && <label htmlFor={props.name} className={`textbox-label ${emptyInput ? "empty" : ""}`}>
          {props.label}
        </label>
      }
      <div className="arrow-up" onClick={addOne}>
        <MdKeyboardArrowUp color={props.maxAllowed === currentValue ? "#e0e0e0" : ""} />
      </div>
      <input
        id={props.id}
        type="number"
        name={props.name}
        disabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : ""}
        {...props.register(props.name, { required: props.required })}
        onChange={onInputChange}
        value={currentValue}
        className={
          `
          form-input-number 
          px-3
          pb-3
          pt-6
          h-full
          text-sm
          ${props.disabled ? "opacity-50" : ""}
          ${props.errors[props.name] && Object.keys(props.errors[props.name]).length > 0 ? "error" : ""}
					${props.label ? "" : "no-label"} 
          `
        }
      />
      <div className={`arrow-down ${downArrowDisabled ? "disabled" : ""}`} onClick={substractOne}>
        <MdKeyboardArrowDown />
      </div>
      {props.subText && (
        <div className="text-xs font-merch-dark-gray opacity-50">
          {props.subText}
        </div>
      )}
      {props.errors[props.name] && Object.keys(props.errors[props.name]).length > 0 && <>
        {
          props.errors[props.name]?.type === 'required' ?
            <span className="error">
              {props.label} is required
            </span> :
            props.errors[props.name]?.types?.custom ?
              <span className="error">
                {props.errors[props.name]?.types.custom}
              </span> :
              <span className="error">
                {props.errors[props.name]?.message}
              </span>
        }
        <MdErrorOutline />
      </>}
    </div>
  </>
}
