import Markdown from 'markdown-to-jsx'
import { ICollectionPageHeaderProps } from ".";

export const CollectionPageHeader = (props: ICollectionPageHeaderProps) => {
  const { merchstore } = props;

  return (
    <div className="flex-col items-center justify-center align-center">
      <div className="flex items-center justify-center">
        <img src={merchstore.bannerImage} className="max-h-200 sm:max-h-500 xl:max-h-500"/>
      </div>
      {(merchstore.storeMessage || merchstore.storeSubMessage) && (
        <div className="flex items-center text-center justify-center my-20 xl:my-30 flex-col">
          <h1 className="store-message-header w-10/12 lg:w-6/12 font-bold text-xl xl:text-3xl ">
            <Markdown>{merchstore.storeMessage || ''}</Markdown>
          </h1>
          <p className="store-message-header w-10/12 lg:w-6/12">
            <Markdown>{merchstore.storeSubMessage || ''}</Markdown>
          </p>
        </div>
      )}
    </div>
  )
}