import * as types from '../constants/ActionTypes';
import { IUser } from '../reduxTypes';

/* * * User * * */
export const loginToMerchStore = (user: IUser) => (dispatch: any) => {

    return new Promise((resolve) => {
        dispatch({
            type: types.LOGIN,
            user
        });
        resolve(true);
    });
}

