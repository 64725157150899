import { useEffect } from "react";
import { useState } from "react";
import { IDropdownProps } from ".";
import "./styles.scss";
import { MdSwapVert, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { generateReactKey } from "@merchstores/shared/react/ReactKey";
import { useRef } from "react";

export const SortDropdown = (props: IDropdownProps) => {

    const { title, list, defaultSort } = props;
    const [listOpen, setListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(title);
    const [checkedItem, setCheckedItem] = useState(defaultSort)
    const wrapperRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closeList();
            }
        }        
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousemove', handleClickOutside);
        }
    }, [checkedItem, list]);

    const toggleList = () => {
        setListOpen(!listOpen);
    }

    const closeList = () => {
        setListOpen(false);
    }

    const selectItem = (item: any) => {
        if (item) {
            const { title } = item;
            setListOpen(false);
            if (item.action) {
                item.action();
            } else {
                setHeaderTitle(title);
            }
        }
        setCheckedItem(item.id)
    }

    

    return (
        <div className={`sort-container ${listOpen ? "open" : ""}`} id={""} ref={wrapperRef}>
            <button
                type="button"
                className="sort-header text-merch-dark-gray font-bold rounded-md"
                onClick={toggleList}
            >
                <div className="sort-header-title">{headerTitle}</div>
                <div className="desktop"> <MdSwapVert size={20} /> </div>
                <div className="mobile"><MdSwapVert size={20} /></div>
            </button>
            {listOpen && (
                <div
                    role="list"
                    className="sort-list pb-2"
                    
                >
                    <span onClick={() => toggleList()} className="sort-list-title p-3">Sort by</span>
                    {list.map((item) => (
                        <div onClick={() => selectItem(item)} className="sort-list-item hover:bg-merch-dark-gray hover:bg-opacity-5 py-1 px-3" style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={generateReactKey(item.id)} >
                            {item.id === checkedItem ?
                                <div className="filter-select-button">
                                    <MdRadioButtonChecked /> 
                                </div>
                            :   <MdRadioButtonUnchecked color="#4B4B55"/>}
                            <span style={{marginLeft: '8px', color: '#4B4B55'}}> {item.title} </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )

}