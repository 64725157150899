import { LOGIN } from "../constants/ActionTypes";
import { UserAction } from "../reduxTypes";

const defaultState = {
  loggedIn: false,
  passwordRequired: false,
  storeCode: [],
};

export default function userReducer(state = defaultState, action: UserAction) {
  const type = action.type;
  const user = action.user;

  switch (type) {
    case LOGIN:
      return { ...user };
    default:
      return state;
  }
}
