import { CTA } from "@merchstores/shared/elements/Cta";
import { useHistory } from "react-router-dom";

export interface ICartKeepShoppingCTAProps {
  type?: "primary" | "secondary",
  display?: boolean,
  classes?: string
}

export const CartKeepShoppingCTA : React.FC<ICartKeepShoppingCTAProps> = (props: ICartKeepShoppingCTAProps) => {
  const history = useHistory();

  if (props.display === false)
  {
    return null;
  }

  const handleGoToSearch = () : boolean =>
  {
    history.push('/');
    return true;
  }

  return (
    <CTA 
      type={ props.type ? props.type : "secondary" } 
      size="standard" 
      classes={ `cart-keep-shopping text-sm ${props.classes ? props.classes : ""} ${props.type ? "cta-custom" : "" }` }
      onClick={handleGoToSearch}>
      Keep Shopping
    </CTA>
  )
}