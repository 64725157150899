import { IMerchStore } from "@merchstores/frontend/reduxTypes";
import { resolveMerchstoreSubdomain } from "./Subdomain";

export function resolveMerchStoreDomain(merchStore: IMerchStore): string {
  if(merchStore.customDomain) {
    return merchStore.customDomain
  }

  const subdomain = resolveMerchstoreSubdomain();
  return `${subdomain}.merchologysolutions.com`
}