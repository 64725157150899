import { loadFromLocalStorage } from "../store";

const isAuthenticated = (storeCode: string): boolean => {
  const localStorage = loadFromLocalStorage();
  return (
    localStorage?.user?.loggedIn &&
    localStorage?.user?.storeCode?.includes(storeCode)
  );
};

export { isAuthenticated };
