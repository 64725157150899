import { useState, useEffect } from "react";
import { MdClose, MdRadioButtonChecked, MdRadioButtonUnchecked, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IFilterMenuProps } from ".";

import './styles.scss'

export const FilterMenu = (props:IFilterMenuProps) => {

  const { displayFilterMenu, setDisplayFilterMenu, setProductFilterChosen, setBrandFilterChosen, productFilters, brandFilters, priceFilters } = props;

  const [checkedProductFilter, setCheckedProductFilter] = useState('All')
  const [checkedBrandFilter, setCheckedBrandFilter] = useState('All')
  const [checkedPriceFilter, setCheckedPriceFilter] = useState('all_prices')
  
  const [showProductFilters, setShowProductFilters] = useState(true)
  const [showPriceFilters, setShowPriceFilters] = useState(true)
  const [showBrandFilters, setShowBrandFilters] = useState(true)

  const [viewMoreProudctFilters, setViewMoreProudctFilters] = useState(false)
  const [viewMoreBrandFilters, setViewMoreBrandFilters] = useState(false)

  const toggleFilterMenu = () => {
    setDisplayFilterMenu(false)
  }

  const selectProductFilter = (item: string) => {
    setProductFilterChosen(item)
    setCheckedProductFilter(item)
  }

  const selectBrandFilter = (item: string) => {
    setBrandFilterChosen(item)
    setCheckedBrandFilter(item)
  }

  const selectPriceFilter = (item: any) => {
    item.action()
    setCheckedPriceFilter(item.id)
  }

  useEffect(() => {
    window.addEventListener('touchend', function(e){
      const target = e.target as HTMLElement;
      if (document.getElementById('FilterMenu')?.contains(e.target as Node) || target.id !== 'filter-button') {
        toggleFilterMenu();
      } else {
        null
      }
    })
  }, []);
  
  return (
    <div className={`filter-menu-container`} id='FilterMenu'>
      
      <div className={`pl-3 lg:pl-8 filter-menu ${displayFilterMenu ? 'active overflow-y-scroll pb-5' : ''}`} onMouseLeave={toggleFilterMenu}>
        <div className="closeButton flex w-full justify-end pr-3 mt-4 cursor-pointer" onClick={toggleFilterMenu}>
          <MdClose size={24}/>
        </div>
        <h3 className="text-18 font-bold mb-4">Filter</h3>
        
        {/* Product Type Filter */}
        <div className="flex justify-between mb-3 mt-4">
          <h4 className="text-14 font-bold ">Product Type</h4>
          <div className="pr-3 cursor-pointer	" onClick={() => setShowProductFilters(!showProductFilters)}>
            {showProductFilters ? <MdKeyboardArrowUp size={23}/> : <MdKeyboardArrowDown size={23}/>}
          </div>
        </div>

        {showProductFilters ?
          !viewMoreProudctFilters ? 
            <div>
              {productFilters.map((item, index) => (
                index <= 4 &&
                <div onClick={() => selectProductFilter(item)} className="sort-list-item cursor-pointer" style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={index} >
                    {item === checkedProductFilter ?
                      <div className="filter-select-button">
                        <MdRadioButtonChecked size={18} /> 
                      </div>
                    : <MdRadioButtonUnchecked size={18} color="#4B4B55"/>}
                    <span style={{marginLeft: '8px', color: '#4B4B55'}}> {item} </span>
                </div>
              ))}
              {productFilters.length > 5 && <button onClick={() => setViewMoreProudctFilters(true)} className="underline text-13 text-merch-text-disabled">View all</button>}
            </div>
          : <div>
              {productFilters.map((item, index) => (
                <div onClick={() => selectProductFilter(item)} className="sort-list-item cursor-pointer" style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={index} >
                  {item === checkedProductFilter ? 
                    <div className="filter-select-button">
                      <MdRadioButtonChecked size={18} /> 
                    </div>
                  : <MdRadioButtonUnchecked size={18} color="#4B4B55"/>}
                    <span style={{marginLeft: '8px', color: '#4B4B55'}}> {item} </span>
                </div>
              ))}
              {productFilters.length > 5 && <button onClick={() => setViewMoreProudctFilters(false)} className="underline text-13 text-merch-text-disabled">View less</button>}
            </div>
        : <div></div>
        }

        {/* Price Filter */}
        <div className="flex justify-between mb-3 mt-4">
          <h4 className="text-14 font-bold">Price</h4>
          <div className="pr-3 cursor-pointer	" onClick={() => setShowPriceFilters(!showPriceFilters)}>
          {showPriceFilters ? <MdKeyboardArrowUp size={23}/> : <MdKeyboardArrowDown size={23}/>}
          </div>
        </div>
        {showPriceFilters ? priceFilters.map((item, index) => (
          <div onClick={() => selectPriceFilter(item)} className="sort-list-item cursor-pointer" style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={index} >
            {item.id === checkedPriceFilter ? 
              <div className="filter-select-button">
                <MdRadioButtonChecked size={18} /> 
              </div>
            : <MdRadioButtonUnchecked size={18} color="#4B4B55"/>}
              <span style={{marginLeft: '8px', color: '#4B4B55'}}> {item.title} </span>
          </div>
        ))
        : <div></div>}

        {/* Brand Filter */}
        <div className="flex justify-between mb-3 mt-4">
          <h4 className="text-14 font-bold">Brand</h4>
          <div className="pr-3 cursor-pointer	" onClick={() => setShowBrandFilters(!showBrandFilters)}>
            {showBrandFilters ? <MdKeyboardArrowUp size={23}/> : <MdKeyboardArrowDown size={23}/>}
          </div>
        </div>

        {showBrandFilters ? 
          !viewMoreBrandFilters ?
            <div>
              {brandFilters.map((item, index) => (
                index <= 4 &&
                <div onClick={() => selectBrandFilter(item)} className="sort-list-item cursor-pointer" style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={index} >
                  {item === checkedBrandFilter ? 
                    <div className="filter-select-button">
                      <MdRadioButtonChecked size={18} /> 
                    </div>
                  : <MdRadioButtonUnchecked size={18} color="#4B4B55"/>}
                    <span style={{marginLeft: '8px', color: '#4B4B55'}}> {item} </span>
                </div>
              ))}
              {brandFilters.length > 5 && <button onClick={() => setViewMoreBrandFilters(true)} className="underline text-13 text-merch-text-disabled">View all</button>}
            </div>
          : 
            <div>
              {brandFilters.map((item, index) => (
                <div onClick={() => selectBrandFilter(item)} className="sort-list-item cursor-pointer" style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={index} >
                  {item === checkedBrandFilter ? 
                    <div className="filter-select-button">
                      <MdRadioButtonChecked size={18} />
                    </div>
                  : <MdRadioButtonUnchecked size={18} color="#4B4B55"/>}
                    <span style={{marginLeft: '8px', color: '#4B4B55'}}> {item} </span>
                </div>
              ))}
              {productFilters.length > 5 && <button onClick={() => setViewMoreBrandFilters(false)} className="underline text-13 text-merch-text-disabled">View less</button>}
            </div>
        : <div></div>}
      </div>
    </div>
  )
}