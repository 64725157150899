import { useState } from "react";
import { MdFilterList } from 'react-icons/md';
import { SortDropdown } from "@merchstores/shared/elements/SortDropdown"
import { FilterMenu } from "@merchstores/shared/elements/FilterMenu"
import { ICollectionPageSortFilterProps } from "."
import "./styles.scss"

function findPriceExtremes(products: any) {
  if (!products || products.length === 0) {
    return { highestPrice: 0, lowestPrice: 0 };
  }

  let highestPrice = parseFloat(products[0].variants[0].price.amount);
  let lowestPrice = parseFloat(products[0].variants[0].price.amount);

  products.forEach((product: any) => {
    product?.variants.forEach((variant: any) => {
      const price = parseFloat(variant.price.amount);
      if (price > highestPrice) highestPrice = price;
      if (price < lowestPrice) lowestPrice = price;
    });
  });

  return { highestPrice, lowestPrice };
}

function getPricePoints(lowestPrice: number, highestPrice: number) {
  const lowPoint = parseFloat(lowestPrice.toFixed(2));
  const highPoint = roundToNearestMultipleOfFive(parseFloat((highestPrice * 0.8).toFixed(2)));
  const midPoint = roundToNearestMultipleOfFive(parseFloat(((highPoint + lowPoint) / 2).toFixed(2)));
  const midLowPoint = roundToNearestMultipleOfFive(parseFloat(((midPoint + lowPoint) / 2).toFixed(2)));
  const midHighPoint = roundToNearestMultipleOfFive(parseFloat(((highPoint + midPoint) / 2).toFixed(2)));

  return {lowPoint, midLowPoint, midPoint, midHighPoint, highPoint};
}

function roundToNearestMultipleOfFive(num: number) {
  return Math.round(num / 5) * 5;
}

export const CollectionPageSortFilter: React.FC<ICollectionPageSortFilterProps> = (props: ICollectionPageSortFilterProps) => {

  const { allProducts, setSortChosen, productFilters, brandFilters, setProductFilterChosen, setBrandFilterChosen, setPriceFilterChosen } = props;
  const [displayFilterMenu, setDisplayFilterMenu] = useState(false);
  
  const sortOptions = [
    {
      title: "Default",
      id: "sort_default",
      action: () => {
        setSortChosen("sort_default")
      }
    },
    {
      title: "Item Name A-Z",
      id: "sort_name_a-z",
      action: () => {
        setSortChosen("sort_name_a-z")
          
      }
    },
    {
      title: "Item Name Z-A",
      id: "sort_name_z-a",
      action: () => {
        setSortChosen("sort_name_z-a")
      }
    },
    {
      title: "Newest",
      id: "sort_newest",
      action: () => {
        setSortChosen("sort_newest")
      }
    },
    {
      title: "Price - Low to High",
      id: "sort_price_low",
      action: () => {
        setSortChosen("sort_price_low")
      }
    },
    {
      title: "Price - High to Low",
      id: "sort_price_high",
      action: () => {
        setSortChosen("sort_price_high")
      }
    },
  ]

  const { highestPrice, lowestPrice } = findPriceExtremes(allProducts);
  const { lowPoint, midLowPoint, midPoint, midHighPoint, highPoint } = getPricePoints(lowestPrice, highestPrice);

  const priceFilterOptions = [
    {
      title: "All",
      id: "all_prices",
      action: () => {
        setPriceFilterChosen({low: lowestPrice, high: highestPrice})
      }
    },
    {
      title: `$${lowPoint} - $${midLowPoint}`,
      id: "low",
      action: () => {
        setPriceFilterChosen({low: lowPoint, high: midLowPoint})
      }
    },
    {
        title: `$${midLowPoint} - $${midPoint}`,
        id: "low-mid",
        action: () => {
          setPriceFilterChosen({low: midLowPoint, high: midPoint})
        }
    },
    {
      title: `$${midPoint} - $${midHighPoint}`,
      id: "mid",
      action: () => {
        setPriceFilterChosen({low: midPoint, high: midHighPoint})
      }
    },
    {
      title: `$${midHighPoint} - $${highPoint}`,
      id: "mid-high",
      action: () => {
        setPriceFilterChosen({low: midHighPoint, high: highPoint})
      }
    },
    {
      title: `$${highPoint} +`,
      id: "high",
      action: () => {
        setPriceFilterChosen({low: highPoint, high: highestPrice})
      }
    },
  ]

  const onFilterButtonClick = () => {
    setDisplayFilterMenu(!displayFilterMenu)
  }
  

  return (
    <>
    <FilterMenu 
      setProductFilterChosen={setProductFilterChosen}
      setBrandFilterChosen={setBrandFilterChosen}
      productFilters={productFilters} 
      brandFilters={brandFilters}
      priceFilters={priceFilterOptions}
      displayFilterMenu={displayFilterMenu} 
      setDisplayFilterMenu={setDisplayFilterMenu} />
    <div className="w-10/12 mx-auto h-50 mt-3">
      <div className="w-full flex justify-between lg:px-50">
        <button className={`filter-button text-merch-dark-gray font-bold text-13 rounded-md h-full`} onClick={() => onFilterButtonClick()} id='filter-button'>
          <MdFilterList size={20}/>
          <div className="pl-2 cursor-pointer" id='filter-button'>Filter</div>
        </button>
        
        <SortDropdown title="Sort" list={sortOptions} defaultSort="sort_default"/>
      </div>
    </div>
    </>
  )
}