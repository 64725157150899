
import { clearCart, removeFromCart, updateInCart } from '@merchstores/frontend/actions/cart'
import { refreshCartProducts } from '@merchstores/frontend/actions/cart'
import { Dispatch } from "redux";
import { History } from 'history';
import { ICart, ICartItem, ICartProduct } from '@merchstores/frontend/reduxTypes';
import { fetchProductsForItems, MissingProductsError } from '@merchstores/frontend/components/Cart/CartProducts';

export async function handleRemoveItem(
  dispatch: Dispatch, 
  item: ICartItem
) : Promise<void> 
{
  await removeFromCart(item)(dispatch);
}

export async function handleUpdateItem(
  dispatch: Dispatch, 
  item: ICartItem
) : Promise<void>
{
  await updateInCart(item)(dispatch);
}

export async function handleFetchCartProducts(
  dispatch: Dispatch, 
  cart: ICart
) : Promise<void>
{
  let products: Array<ICartProduct> = [];
  
  try {
    products = await fetchProductsForItems(cart.items);
  } 
  catch(error) 
  {
    if (error instanceof MissingProductsError)
    {
      console.error(`Removed unavailable products: ${ error.getMissingHandles() }`)
      products = error.getAvailableProducts();
    }
  }
  
  await refreshCartProducts(products)(dispatch);
}

export async function handleClearCart(
  dispatch: Dispatch, 
  history: History
) : Promise<void>
{
  const urlParams = new URLSearchParams(window.location.search);

  const clearCartParam = /\/clear$/ig.test(window.location.href) || urlParams.get('clear');
  if (clearCartParam) 
  {
    clearCart()(dispatch).then(() => {
      history.push('/cart');
    });
  }
}
