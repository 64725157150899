import { useForm } from "react-hook-form";
import { FormInput } from "@merchstores/shared/elements/FormInput";
import { CartContext } from "@merchstores/frontend/context/Cart";
import { isValidEmail } from "@merchstores/frontend/utils/email";
import { CTA } from "@merchstores/shared/elements/Cta";
import { moneyFormat } from "../Money";
import { useContext, useEffect } from "react";
import useCartMemberSummaryAsync from "@merchstores/frontend/hooks/members/useCartMemberSummaryAsync";
import BigNumber from "bignumber.js";

interface Props {
  storeCode: string;
  subTotal: string;
}

interface FormValues {
  email: string;
}

const CartGiftCard = (props: Props): JSX.Element => {
  const { storeCode, subTotal } = props;
  const { register, handleSubmit, formState: { errors, isSubmitting, dirtyFields }, setError, setValue } = useForm();
  const { isLoading, getCartMemberSummaryAsync } = useCartMemberSummaryAsync();
  const { state: { member }} = useContext(CartContext);

  useEffect(() => {
    if (member && member.email) {
      setValue("email", member.email, { shouldDirty: true });
    }
  }, []);

  const onSubmit = (values: FormValues) => {
    const validEmail = isValidEmail(values.email);
    if (validEmail) {
      getCartMemberSummaryAsync({ storeCode, memberEmail: values.email });
    } else {
      setError("email", { message: 'Invalid email' });
    }
  };

  const giftCardBalanceNumber = new BigNumber(member?.giftCard?.balance);
  const subTotalNumber = new BigNumber(subTotal.replace("$", ""));

  const giftCardBalanceApplied = subTotalNumber.isGreaterThanOrEqualTo(giftCardBalanceNumber) ? giftCardBalanceNumber : subTotalNumber;
  const giftCardBalanceRemaining = subTotalNumber.isLessThan(giftCardBalanceNumber) ? giftCardBalanceNumber.minus(subTotalNumber) : new BigNumber(0);


  return (
    <div className="pt-5">
      <div className="flex grid-cols-2 justify-between gap-3">
        <div className="text-sm text-merch-dark-gray font-bold">Company Email Address</div>
        <div className="flex flex-col items-end">
          <p className="italic text-xs text-gray-400">Required</p>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <p className="text-xs text-gray-400 my-1">If you&#39;ve received a gift card your balance will automatically be applied to your order total.</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex grid-cols-2 justify-between gap-3">
        <FormInput
          inputStyle="two"
          errors={errors}
          name="email"
          type="text"
          placeholder="name@company.com"
          register={register}
          required={true}
        />
        <div className="flex flex-col items-end">
          <CTA
            type="merchstore-primary"
            formSubmit={true}
            size="flex"
            classes={`h-11 px-4 mt-3`}
            disabled={isLoading || isSubmitting || !dirtyFields.email}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </CTA>
        </div>
      </form>
      {member?.giftCard?.balance && (
        <div>
          <div className="flex grid-cols-2 w-full">
            <p className="w-full font-bold text-14 text-gray-400">Total gift card balance:</p>
            <p className="w-full text-14 text-right">{moneyFormat(`${giftCardBalanceNumber}`)}</p>
          </div>
          <div className="flex grid-cols-2 w-full">
            <p className="w-full font-bold text-14 text-gray-400">Gift card balance applied:</p>
            <p className="w-full text-14 text-right">{moneyFormat(`-${giftCardBalanceApplied}`)}</p>
          </div>
          <div className="flex grid-cols-2 w-full">
            <p className="w-full font-bold text-14 text-gray-400">Gift card balance remaining:</p>
            <p className="w-full text-14 text-right">{moneyFormat(giftCardBalanceRemaining)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartGiftCard;