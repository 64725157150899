import { useState, useContext } from "react";
import { IFetchMemberCartSummaryInput } from "@merchstores/frontend/types/members";
import { fetchMemberCartSummary } from "@merchstores/frontend/api/members";
import { CartContext } from "@merchstores/frontend/context/Cart";

interface UseCartMemberSummaryAsyncResult {
  isLoading: boolean;
  getCartMemberSummaryAsync: (
    params: IFetchMemberCartSummaryInput
  ) => Promise<void>;
}

const useCartMemberSummaryAsync = (): UseCartMemberSummaryAsyncResult => {
  const [isLoading, setIsLoading] = useState(false);
  const { setCartMember } = useContext(CartContext);

  const getCartMemberSummaryAsync = async (
    params: IFetchMemberCartSummaryInput
  ) => {
    setIsLoading(true);
    const data = await fetchMemberCartSummary(params);
    setCartMember({ email: params.memberEmail, giftCard: data.giftCard });
    setIsLoading(false);
  };

  return { isLoading, getCartMemberSummaryAsync };
};

export default useCartMemberSummaryAsync;
