const RESOLVE_FROM_LOCATION_HOST_FLAG = "@resolve_from_location_host";


export function resolveMerchstoreSubdomain(): string {
  // Try to resolve by env
  const envSubdomain = process.env.REACT_APP_MERCHSTORE_SUBDOMAIN;

  const isResolveFromLocationHost = envSubdomain === RESOLVE_FROM_LOCATION_HOST_FLAG;

  if (envSubdomain && !isResolveFromLocationHost)
  {
    return envSubdomain;
  }

  // Special Value: "@resolve_from_location_host"
  // Fallback: subdomain.merchologysolutions.com
  const location = window.location.host;

  if (location.includes(".merchologysolutions.com")) 
  {
    const merchologySubdomain = location.split(".")[0];
    return merchologySubdomain;
  } 


  throw new Error("Unable to resolve merchstore subdomain");
}
