import { createStorefrontApiClient } from "@merchstores/frontend/api/shopify-storefront/StorefrontApiClient";
import { makeGetProductByHandle } from "@merchstores/frontend/queries/storefront-product-details-2022-10";
import { 
  IGraphMoney,
  IGraphImageEdge, 
  IGraphImageNode, 
  IGraphVariantEdge
} from "@merchstores/frontend/api/shopify-storefront/ShopifyProduct";

export interface IProductResult {
  handle: string,
  title: string,
  price: {
    amount: string,
    currency: string
  }
  variants: IProductResultVariant[];
  images: IGraphImageNode[];
  tags: string[];
  id: string;
}

export interface IProductResultVariant {
  id: string
  price: IGraphMoney
  images: IGraphImageNode[]
}

export async function getProductDetails(producthandle: string): Promise<IProductResult> {
  const storefrontClient = createStorefrontApiClient();

  const response = await storefrontClient.post('', makeGetProductByHandle(producthandle));

  const { data: responseData } = response;
  const { productByHandle: productData } = responseData;

  const resultProductVariants = productData.variants.edges.map( 
    (variantEdge: IGraphVariantEdge) => {
      return variantEdge.node;
    }
  )

  const resultProductImages = productData.images.edges.map( 
    (imageEdge: IGraphImageEdge) => {
      return imageEdge.node;
    }
  )

  return {
    ...productData,
    images: resultProductImages,
    variants: resultProductVariants
  };
}