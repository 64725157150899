import { IProduct, IProductVariant } from "@merchstores/frontend/reduxTypes";
import { debugLog } from "@merchstores/frontend/components/Debug";
import axios from "axios";
import { parseMerchStoresSku } from "./MerchServicesSku";
import { resolveMerchStoreApiUrl } from "@merchstores/frontend/api/merchstore";

const NO_QUANTITY_INFO = -1;
const MIN_VARIANT_STOCK = 150;

export const MERCHOLOGY_VARIANT_ID_REGEX = /<vid:\/\/[^>]+?>/gi;

export interface IInventoryVariant {
  merchologyVariantId: string;
  quantityAvailable: number;
}

export interface IInventoryProduct {
  variants: Map<string, IInventoryVariant>
}

export async function getInventoryProduct(
  merchologyProductId: string, 
  storeProductId: string
): Promise<IInventoryProduct|null> 
{
  const inventoryResponse = await axios.post(
    resolveMerchStoreApiUrl('/.netlify/functions/merchstore-api/fetchProductQuantity'), {
    merchologyProductId,
    storeProductId
  });

  if (!inventoryResponse) {
    return null;
  }

  const product = inventoryResponse.data.product;
  const variantsById = new Map();

  product.variants.forEach((variant: IInventoryVariant) => {
    variantsById.set(variant.merchologyVariantId, variant);
  });

  product.variants = variantsById;

  return product;
}

export function applyOutOfStockOnVariantQuantities(variants: Array<IProductVariant>, product?: IProduct): Array<IProductVariant> {
  const stockExceptionTags = ['bnsl'];
  const productTags = product?.tags?.map(tag => tag.toLowerCase()) || [];
  const stockExceptions = productTags.some(tag => stockExceptionTags.includes(tag));

  const adjustedVariants = variants.map(variant => {

    const variantCopy = JSON.parse(JSON.stringify(variant));

    if (variantCopy.quantityAvailable < MIN_VARIANT_STOCK && !stockExceptions) {
      variantCopy.quantityAvailable = 0;
    } 

    return variantCopy;
  });

  return adjustedVariants;
}

export async function applyProductVariantQuantities(product: IProduct): Promise<Array<IProductVariant>> {
  const merchologyProductTag = (product.tags || []).find(
    (t: string) => /merchology_source_product_id=/ig.test(t)
  ) || '';

  const merchologyProductId = String(merchologyProductTag).replace('merchology_source_product_id=', '');
  const storeProductId = product.id.replace(/.*\//g, '');

  const inventoryProduct = await getInventoryProduct(merchologyProductId, storeProductId).catch(err => {
    console.log("getInventoryProduct", err);
    return null;
  });

  if (!inventoryProduct) {
    return product.variants;
  }

  const variants = product.variants.map(variant => {
    const newVariant = JSON.parse(JSON.stringify(variant));

    //variant.sku is required to properly match the merchstore variant with merchology variant
    if (!variant.sku) {
      return newVariant;
    }

    const { merchologyVariantId } = parseMerchStoresSku(variant.sku);
    const merchologyVariant = inventoryProduct.variants.get(merchologyVariantId);
    
    if (merchologyVariant && merchologyVariant.quantityAvailable > NO_QUANTITY_INFO ) {
      debugLog(`Inventory update ${variant.quantityAvailable} -> ${merchologyVariant.quantityAvailable}`)
      newVariant.quantityAvailable = merchologyVariant.quantityAvailable;
    }

    return newVariant;
  });

  return variants;
}

export function skuWithoutVariantIdTag(originalSku: string): string {
  return originalSku.replace(MERCHOLOGY_VARIANT_ID_REGEX, '').trim();
}