
import {
    FETCH_STORE_INFO_END
} from "../constants/ActionTypes";
import { MerchstoreAction } from "../reduxTypes";


import { applyAccentColor, applyHeaderColor, applyBackgroundColor } from '@merchstores/frontend/elements/StyleCustomColors';

export default function storeReducer(state = {
    status: "draft",
    customerAdmins: [],
    subdomain: "",
    storeLogo: "",
    bannerImage: "",
    storeMessage: "",
    revenue: 0,
    accentColor: "",
    headerColor: "",
    backgroundColor: "",
    merchologist: "",
    payerType: "",
    alwaysOpen: true,
    processingSchedule: "",
    nextProcessingDate: "",
    artworkOptions: [],
    shipToOffice: false,
    name: "",
    storeCode: ""

}, action: MerchstoreAction) {

    const type = action.type;
    const merchstore = action.merchstore;

    applyAccentColor(state.accentColor);
    applyHeaderColor(state.headerColor);
    applyBackgroundColor(state.backgroundColor);

    switch (type) {
        case FETCH_STORE_INFO_END:
            return { ...merchstore }
        default:
            return state;
    }

}