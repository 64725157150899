


export function makeGetProductByHandle(productHandle: string): { query: string} {
  const query = `
  {
    productByHandle(
      handle: "${productHandle}"
    ) {
      title
      id
      description
      descriptionHtml
      productType
      vendor
      createdAt
      handle
      tags
      images(first: 10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            src: url(
              transform: {
                maxWidth: 1024, 
                maxHeight: 1024, 
                preferredContentType: JPG
              }
            )
            id
            altText
          }
        }
      }
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            product {
              id
            }
            title
            price {
              amount
              currencyCode
            }
            sku
            availableForSale
            quantityAvailable
            image {
              src: url(
                transform: {
                  maxWidth: 1024, 
                  maxHeight: 1024, 
                  preferredContentType: JPG
                }
              )
              id
              altText
            }
            compareAtPrice {
              amount
              currencyCode
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
  `

  return {
    query: query
  }
}