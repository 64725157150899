/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useReducer } from "react";
import { IMember } from "../types/members";

enum CartAction {
  SET_CART_MEMBER = "setCartMember",
}

type State = {
  member: IMember | null;
};

type Action = {
  type: CartAction.SET_CART_MEMBER;
  payload: {
    member: IMember;
  };
};

interface Props {
  children: JSX.Element
}

const initialState: State = {
  member: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case CartAction.SET_CART_MEMBER:
      return { ...state, member: action.payload.member };
    default:
      return { ...state };
  }
};

const CartContext = React.createContext({
  state: initialState,
  dispatch: () => { },
  setCartMember: () => { },
});

const CartContextProvider = (props: Props): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const setCartMember = (member: IMember): void => {
    dispatch({
      type: CartAction.SET_CART_MEMBER,
      payload: { member }
    });
  };

  return (
    <CartContext.Provider
      value={{
        state,
        dispatch,
        setCartMember
      }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartContextProvider };
