export function isApparel(productType: string): boolean {
  return [
    'polos', 
    'fleece', 
    'shirts', 
    'jackets', 
    'sweatshirts', 
    't-shirts', 
    'vests', 
    'sweaters', 
    'layering', 
    'bottoms', 
    'headwear'
  ].includes(productType.toLowerCase());
}