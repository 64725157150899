import axios from "axios";
import { GET_MEMBER_CART_SUMMARY } from "../routes";
import {
  IFetchMemberCartSummaryInput,
  IMemberCartSummary,
} from "@merchstores/frontend/types/members";

const fetchMemberCartSummary = async (
  params: IFetchMemberCartSummaryInput
): Promise<IMemberCartSummary> => {
  const result = await axios.post(GET_MEMBER_CART_SUMMARY, params);
  return result.data;
};

export { fetchMemberCartSummary };
