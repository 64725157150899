import { Card } from "@merchstores/shared/elements/Card";
import { CTA } from "@merchstores/shared/elements/Cta";
import { FormInput } from "@merchstores/shared/elements/FormInput";
import { IMerchStore, IStoreState, IUser } from "@merchstores/frontend/reduxTypes";
import { loginToMerchStore } from "../../actions/user";
import { useForm, useWatch } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux"
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";


import './styles.scss';
import { IMerchStoreLoginProps } from ".";
import { resolveMerchStoreApiUrl } from "@merchstores/frontend/api/merchstore";

export const MerchStoreLogin = (props: IMerchStoreLoginProps): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch()
  const { register, handleSubmit, formState: { errors }, setError, control } = useForm();
  const merchstore: IMerchStore = useSelector((state: IStoreState) => state.merchstore, shallowEqual)
  const user: IUser = useSelector((state: IStoreState) => state.user, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const password = useWatch({
    control,
    name: "password",
    defaultValue: ""
  });

  useEffect(() => {
    if (password.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password]);

  const onSubmit = (vals: any) => {
    setLoading(true);
    if (vals.password) {
      if (merchstore) {
        // Check if there is a password
        axios.post(
          resolveMerchStoreApiUrl("/.netlify/functions/merchstore-api/checkMerchStorePassword"),
          {
            enteredPassword: vals.password,
            subdomain: merchstore.subdomain
          })
          .then((res) => {
            if (res.data.loginSuccess) {
              props.onCloseModal();
              dispatch(
                loginToMerchStore({
                  loggedIn: true,
                  storeCode: [...user.storeCode, merchstore.storeCode] as string[],
                  passwordRequired: true
                }))
            }
            else {
              setLoading(false);
              setError("password", { type: "incorrect", message: "This password is incorrect." });
            }
          })
          .catch(err => {
            console.error('Failed getting MerchStore Password', err);
          })
      }
    }
  }

  return (
    <div className="absolute top-0 z-50 bg-gray-300 h-full w-full bg-opacity-50">
      <div className="flex justify-center items-center relative h-4/6 ">
        <div className="group-member-page-login-container flex justify-center items-center mt-40">
          <Card>
            <div className="flex flex-col justify-between items-center h-full pt-40 pb-30 pr-30 pl-30">
              <div>
                {merchstore.storeLogo ?
                  <img alt="Store Logo" className="object-contain" src={merchstore.storeLogo} />
                  : <div className="h-60"></div>}
              </div>
              <h1 className="text-center text-lg mt-20 mb-8px font-bold">
                Welcome to the {merchstore?.name} MerchStore!
              </h1>
              <p className="text-center text-base mb-15">Please enter the password.</p>
              <form onSubmit={handleSubmit(onSubmit)} className="form w-full">
                <FormInput
                  errors={errors}
                  label="Password"
                  name="password"
                  type="password"
                  register={register}
                  required={true}
                />
                <CTA
                  type="primary"
                  formSubmit={true}
                  size="flex"
                  classes={`w-full mt-5 `}
                  disabled={loading || disabled ? true : false}
                >
                  {loading ? "Submitting..." : "Submit"}
                </CTA>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}