interface IParsedSku {
    shopifySku: string,
    merchologyVariantId: string,
    merchologyVariantSku: string,
    merchStoresSku: string
}

export function parseMerchStoresSku(shopifySku: string): IParsedSku {
    shopifySku = String(shopifySku).trim();
  
    const normalizedShopifySku = shopifySku.replace(/gid:\/\/shopify\/ProductVariant\//ig, '');
  
    let merchologyVariantId = '';
    let merchologyVariantSku = '';
    let merchStoresSku = '';
  
    const merchologyVariantIdRegex = /<vid:\/\/([^>]+)>/gi;
    const merchologyVariantIdMatch = merchologyVariantIdRegex.exec(shopifySku)
    const isVariantNumber = /^\d+$/g.test(shopifySku);
  
    if (merchologyVariantIdMatch) {
      merchologyVariantId = merchologyVariantIdMatch[1];
      merchologyVariantSku = normalizedShopifySku.replace(merchologyVariantIdRegex, '').replace(merchologyVariantId, '').trim();
      merchStoresSku = normalizedShopifySku;
    }
    else if (isVariantNumber) {
      merchologyVariantId = shopifySku;
      merchologyVariantSku = '';
      merchStoresSku = '';
    }
  
    return {
      shopifySku: shopifySku,
      merchologyVariantId,
      merchologyVariantSku,
      merchStoresSku
    }
  }