import { ICartItem, ICartProduct } from '@merchstores/frontend/reduxTypes';

export const uniqueItemKey = (item: ICartItem) : string =>
{
  return [
    item.id, 
    item.size, 
    item.artwork, 
    item.decorationType, 
    item.decorationLocation
  ].map(String).join("--");
}

export const itemListHasItem = (cartItems: Array<ICartItem>, item: ICartItem): boolean => 
{
  const itemKey = uniqueItemKey(item);
  const itemListKeys = cartItems.map(uniqueItemKey);

  return itemListKeys.includes(itemKey);
}

export const itemListMergeItem = (cartItems: Array<ICartItem>, newItem: ICartItem): Array<ICartItem> => 
{
  const mergedItemList: Array<ICartItem> = [];

  cartItems.forEach(item =>
  {
    if (uniqueItemKey(item) === uniqueItemKey(newItem)) {
      mergedItemList.push({ ...item, quantity: newItem.quantity + (item.quantity ? item.quantity : 0) }) // Increment qty
    } else {
      mergedItemList.push({ ...item, quantity: item.quantity });
    }
  });

  if (!itemListHasItem(mergedItemList, newItem))
  {
    mergedItemList.push({ ...newItem })
  }

  return mergedItemList;
}

export const itemListRemoveItem = (cartItems: Array<ICartItem>, itemToRemove: ICartItem): Array<ICartItem> => 
{
  return cartItems.filter(listItem => {
    return uniqueItemKey(itemToRemove) !== uniqueItemKey(listItem);
  })
}

export const itemListReplaceItem = (cartItems: Array<ICartItem>, itemToReplace: ICartItem): Array<ICartItem> => 
{
  return cartItems.map(listItem => {
    if (uniqueItemKey(itemToReplace) === uniqueItemKey(listItem))
    {
      return {...itemToReplace };
    }

    return listItem;
  });
}

export const itemListFilteredByProducts = (cartItems: Array<ICartItem>, productsToKeep: Array<ICartProduct>): Array<ICartItem> => 
{
  const productHandles = productsToKeep.map(product => (product as ICartProduct).handle);

  return cartItems.filter(cartItem => {
    return cartItem.handle && productHandles.includes(cartItem.handle);
  })
}

