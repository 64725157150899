export const CLOSE_DATE_PENDING = "pending";

export interface ICloseDate
{
  isDefined: () => boolean,
  getCloseDate: () => Date,
  closeDateTag: () => string,
}

export class CloseDate implements ICloseDate
{
  closeDate: Date|undefined;

  constructor(closeDate?: Date)
  {
    if (closeDate)
    {
      this.closeDate = closeDate;
    }
  }

  isDefined(): boolean
  {
    return this.closeDate instanceof Date;
  }

  getCloseDate(): Date
  {
    if (!this.closeDate)
    {
      throw new Error("Close Date is undefined");
    }

    return this.closeDate;
  }

  closeDateTag()
  {
    const closeDateDt = this.closeDate;
    return closeDateDt ? closeDateDt.toISOString().replace(/T.*/g, '') : CLOSE_DATE_PENDING;
  }

  toString() : string {
    return this.closeDateTag();
  }
}